@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(/static/media/Montserrat-Bold.63fae500.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(/static/media/Montserrat-Medium.3a091734.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(/static/media/Montserrat-Regular.ac98a5f5.ttf) format('opentype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(/static/media/Montserrat-SemiBold.04af9a9e.ttf) format('opentype');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'), url(/static/media/OpenSans-Regular.57cd57a6.ttf) format('opentype');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'), url(/static/media/Raleway-Regular.3927fa2a.ttf) format('opentype');
}

@font-face {
  font-family: 'OpenSans_Condensed-Regular';
  src: local('OpenSans_Condensed-Regular'), url(/static/media/OpenSans_Condensed-Regular.b740e0ce.ttf) format('opentype');
}

@font-face {
  font-family: 'OpenSans_SemiCondensed-Regular';
  src: local('OpenSans_SemiCondensed-Regular'), url(/static/media/OpenSans_SemiCondensed-Regular.874865e9.ttf) format('opentype');
}

@font-face {
  font-family: 'RobotoMono-Regular';
  src: local('RobotoMono-Regular'), url(/static/media/RobotoMono-Regular.34e46962.ttf) format('opentype');
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  min-height: 100% !important;
  height: 100%;
}

img {
  object-fit: cover;
  border: none;
}

button {
  border: none;
  cursor: pointer;
}

html {
  min-height: 100% !important;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
ul {
  margin: 0;
  padding: 0;
}
